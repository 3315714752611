import { apiRequest } from "src/async/apiUtils";
import { baseUrl } from "src/config/host";

export const convertToBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl, {
      headers: { "Cache-Control": "no-cache" },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return null;
  }
};

export function convertBase64ToPng(base64Input) {
  return new Promise((resolve, reject) => {
    if (!base64Input) {
      reject("No base64 input");
      return;
    }

    const mimeType = base64Input.split(";")[0].split(":")[1];
    const fromType = mimeType.split("/")[1];

    const img = new Image();
    img.src = base64Input;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (fromType === "svg+xml") {
        const svgData = atob(base64Input.split(",")[1]);
        const svgElement = new DOMParser().parseFromString(svgData, "image/svg+xml").documentElement;
        const svgUrl = URL.createObjectURL(new Blob([svgElement.outerHTML], { type: "image/svg+xml" }));
        const imgSvg = new Image();
        imgSvg.src = svgUrl;
        imgSvg.onload = () => {
          ctx.drawImage(imgSvg, 0, 0);
          resolve(canvas.toDataURL(`image/png`));
        };
      } else {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL(`image/png`));
      }
    };

    img.onerror = (err) => {
      reject("Image conversion failed");
    };
  });
}

export const getS3ImageDetails = async (key) => {
  try {
    const { data: resData } = await apiRequest(
      "GET",
      baseUrl + `${key}`,
      {},
      {
        useCustomUrl: true,
        responseType: "blob",
      },
    );

    return {
      status: 200,
      data: URL.createObjectURL(resData),
    };
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const fileExtensions = {
  txt: ["txt", "csv"],
  document: ["pdf", "doc", "docx", "odt"],
  spreadsheet: ["xls", "xlsx", "ods"],
  image: ["jpg", "jpeg", "png", "gif", "svg"],
  audio: ["mp3", "wav"],
  video: ["mp4", "avi", "mov"],
  compressed: ["zip", "rar"],
  presentation: ["ppt", "pptx"],
};
