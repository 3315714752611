import ToggleInterval from "./paymentTabs/overview/ToggleInterval";
import { ForwardIcon } from "@heroicons/react/24/outline";
import { apiRequest } from "src/async/apiUtils";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../form/Button";

const AssignedPlan = ({ plan, workspaceDetails, interval, setPaymentDetails, setView, setInterval }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubscribe = async () => {
    try {
      setButtonLoading(true);
      const response = await apiRequest("post", `/portal/workspaces/:workspace_id/billing/payment/intent`, { body: { interval }, params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        setPaymentDetails(response?.data?.data);
        setView("payForm");
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div className="relative mb-4 h-full w-full  p-4">
      <div className="mt-4">
        <div className="flex w-full items-center gap-2 text-4xl font-semibold">{plan?.name}</div>
        <div className="py-1 pl-0.5 text-sm opacity-75">{plan?.description}</div>
      </div>
      <div className="mt-2">
        <ToggleInterval
          plan={plan}
          interval={interval}
          setInterval={setInterval}
        />
      </div>
      <div className="mt-4 flex justify-end border-t pt-3">
        <Button
          isDisable={buttonLoading}
          buttonClasses="!bg-highlightColor"
          buttonIconPosition="right"
          buttonIcon={ForwardIcon}
          buttonLabel="Proceed To Payment"
          buttonFunction={handleSubscribe}
        />
      </div>
    </div>
  );
};

export default AssignedPlan;
