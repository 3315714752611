import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

export default function InformationAlert({ content, type }) {
  let typeColors = {
    info: { primary: "text-blue-800 border-blue-600", secondary: "bg-blue-200 " },
    warning: { primary: "text-amber-800 border-amber-600", secondary: "bg-amber-50 " },
    danger: { primary: "text-red-800 border-red-600", secondary: "bg-red-200 " },
  };

  return (
    <div className={classNames("mt-4 flex gap-x-2 rounded border pr-2", typeColors[type].primary)}>
      <div className={classNames("flex flex-shrink-0 items-center justify-center rounded rounded-r-none px-2 py-1 text-lg font-bold", typeColors[type].secondary)}>
        {type === "info" && <InformationCircleIcon className="h-6 w-6 stroke-2" />}
        {type === "warning" && <ExclamationTriangleIcon className="h-6 w-6 stroke-2" />}
        {type === "danger" && <ExclamationCircleIcon className="h-6 w-6 stroke-2" />}
      </div>{" "}
      <div className="flex-shrink flex-grow whitespace-normal py-2">{content}</div>
    </div>
  );
}
