import { WorkspaceContext } from "src/context/workspace";
import React, { useContext } from "react";
import PlanResubscribe from "./PlanResubscribe";
import Plan from "./Plan";

const Overview = ({ activePlan, onHold, setOnHold, interval, setInterval, loadPlanDetails, setPaymentDetails = () => {}, setView = () => {} }) => {
  const { details: workspaceDetails } = useContext(WorkspaceContext);

  return (
    <div className="relative mb-4 flex h-full w-full flex-col space-y-4 bg-white">
      <Plan
        type="current"
        title={"Current Plan"}
        planData={activePlan}
        loadPlanDetails={loadPlanDetails}
        workspaceDetails={workspaceDetails}
      />
      {activePlan?.upcoming_plan && (
        <Plan
          type={activePlan?.upcoming_plan?.payment_status === "pending" ? "pending_upcoming" : "upcoming"}
          title={"Upcoming Plan"}
          setView={setView}
          interval={interval}
          setInterval={setInterval}
          planData={activePlan?.upcoming_plan}
          loadPlanDetails={loadPlanDetails}
          workspaceDetails={workspaceDetails}
          setPaymentDetails={setPaymentDetails}
        />
      )}
      {!activePlan?.upcoming_plan && ["on_trial", "canceled", "expired", "on_trial_expired"].includes(activePlan?.subscription_status) && (
        <PlanResubscribe
          onHold={onHold}
          setView={setView}
          interval={interval}
          setOnHold={setOnHold}
          activePlan={activePlan}
          setInterval={setInterval}
          workspaceDetails={workspaceDetails}
          setPaymentDetails={setPaymentDetails}
        />
      )}
    </div>
  );
};

export default Overview;
