import { CalendarIcon, CreditCardIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/form/Button";
import Modal from "src/components/shared/Modal";
import { formatCurrency } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import { getStatusStyles } from "src/helpers/planHelper";
import ToggleInterval from "./ToggleInterval";

const Plan = ({ type, title, planData, workspaceDetails, loadPlanDetails, setPaymentDetails, setView, interval, setInterval = () => {} }) => {
  const { className, label } = getStatusStyles(type === "current" ? planData?.subscription_status : "upcoming");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleOnSuccess = async () => {
    try {
      setIsBtnLoading(true);

      const method = type === "current" ? "delete" : type === "upcoming" ? "put" : "post";
      const endpoint = type === "pending_upcoming" ? `/portal/workspaces/:workspace_id/billing/payment/intent` : `/portal/workspaces/:workspace_id/billing/plan/${type === "current" ? "cancel" : "active"}`;

      const body = type === "pending_upcoming" ? { interval: planData?.subscription?.recurring_type } : {};

      const response = await apiRequest(method, endpoint, { body: body, params: { workspace_id: workspaceDetails?._id } });

      if (response?.data?.status === 200) {
        if (type === "pending_upcoming") {
          setPaymentDetails(response?.data?.data);
          setView("payForm");
        } else {
          toast.success(response?.data?.message);
          loadPlanDetails(workspaceDetails?._id, true);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setConfirmationModalOpen(false);
      setIsBtnLoading(false);
    }
  };

  return (
    <div className="rounded-lg border p-4">
      <div className="">
        <div className="mb-4 flex items-center justify-between border-b pb-2">
          <div className="font-semibold">{title}</div>

          {type === "current" ? (
            <div className="flex items-center justify-center gap-2 rounded-md px-2 text-sm">
              {planData?.free_trial_days_left >= 0 && (
                <div className="flex flex-shrink-0 items-baseline whitespace-nowrap">
                  <span className="font-semibold text-gray-500">Free Trial:</span>
                  <span className="ml-2 text-sm font-semibold text-highlightColor">{planData?.free_trial_days_left} day(s) left</span>
                  <span className="ml-2 text-lg">|</span>
                </div>
              )}
              <div className="flex items-center justify-center font-medium  text-gray-500">
                {["canceled", "expired", "on_trial", "on_trial_expired"].includes(planData?.subscription_status) ? (moment(planData?.end_date).isSameOrAfter(moment(), "day") ? "Expiring on:" : "Expired on:") : "Next Billing Date:"}
              </div>
              <div className="flex items-center justify-center gap-1 font-semibold text-highlightColor">
                <CalendarIcon className="h-4 w-4" />
                {moment(planData?.end_date)
                  .add(["canceled", "expired", "on_trial", "on_trial_expired"].includes(planData?.subscription_status) ? 0 : 1, "days")
                  .format("MMM DD, YYYY")}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2 rounded-md px-2 text-sm font-medium text-slate-600">
              Will automatically get {type === "pending_upcoming" ? "assigned" : "activated"} on{" "}
              <span className="flex items-center justify-center gap-1 font-semibold  text-highlightColor">
                <CalendarIcon className="h-4 w-4" />
                {moment(planData?.start_date).format("MMM DD, YYYY")}
              </span>
            </div>
          )}
        </div>
        <div className="flex w-full items-center gap-2 text-lg font-semibold">
          {planData?.plan_details?.name}
          {<div className={classNames("flex h-6 items-center justify-center gap-2 rounded-md border px-2 text-sm", className)}>{label}</div>}
        </div>
        <div className="mb-4 py-1 pl-0.5 text-[13px] font-medium text-gray-500">{planData?.plan_details?.description}</div>
      </div>
      <div className="mt-6 flex justify-between gap-3">
        {!["on_trial", "on_trial_expired"].includes(planData?.subscription_status) && type !== "pending_upcoming" && (
          <div className="flex items-center justify-between gap-1 whitespace-nowrap pt-2  ">
            <div className="!mt-auto rounded-md border border-gray-200 px-3 py-1 text-lg font-bold text-highlightColor ">
              {`${formatCurrency(planData?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === planData?.subscription?.recurring_type)?.amount - planData?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === planData?.subscription?.recurring_type)?.discount_amount || 0)}`}
              <span className="text-base font-medium text-gray-400"> / {planData?.subscription?.recurring_type}</span> <span className="text-base font-semibold capitalize text-gray-500">| {"Billed " + planData?.subscription?.recurring_type + "ly"}</span>
            </div>
          </div>
        )}
        {type === "pending_upcoming" && (
          <ToggleInterval
            plan={planData?.plan_details}
            interval={interval}
            setInterval={setInterval}
          />
        )}
        {!["canceled", "expired", "on_trial", "on_trial_expired"].includes(planData?.subscription_status) && (
          <div className="">
            <Button
              buttonClasses={type === "current" ? "bg-red-600 text-white" : "bg-highlightColor"}
              buttonIconPosition="left"
              buttonIcon={type === "current" ? XMarkIcon : CreditCardIcon}
              buttonLabel={type === "current" ? "Cancel Subscription" : type === "upcoming" ? "Activate Now" : "Pay & Activate Now"}
              buttonLabelClasses={"font-medium whitespace-nowrap"}
              buttonFunction={() => setConfirmationModalOpen(true)}
            />
          </div>
        )}
      </div>
      <ConfirmationModal
        title={"Subscription"}
        subtitle={type === "current" ? "Cancel" : "Activate"}
        isOpen={confirmationModalOpen}
        disable={isBtnLoading}
        onSuccess={handleOnSuccess}
        onCancel={() => setConfirmationModalOpen(false)}
        content={
          type === "current" ? (
            <div className="mb-2 text-base ">
              If you cancel the current subscription, your access to <span className="font-semibold text-highlightColor">{workspaceDetails?.name}</span> will end after{" "}
              <span className="font-semibold text-highlightColor">{moment(planData?.end_date).format("MMM DD, YYYY")} , </span>
              Are you sure you want to cancel the subscription?
            </div>
          ) : type === "upcoming" ? (
            "Are you sure you want to activate the subscription now ?"
          ) : (
            "Are you sure you want to pay and activate the subscription now"
          )
        }
      />
    </div>
  );
};

export default Plan;

const ConfirmationModal = ({ title, subtitle, isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {}, content }) => {
  return (
    <Modal
      title={title}
      secondaryTitle={subtitle}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: true,
        onCancelButtonText: "No",
      }}>
      {content}
    </Modal>
  );
};
