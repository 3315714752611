import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "src/components/shared/Modal";
import { CardForm } from "src/components/stripeForm/CardForm";

const CardAddModal = ({ isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {}, paymentDetails }) => {
  const stripePromise = loadStripe(paymentDetails?.stripe_publishable_key);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: paymentDetails?.client_secret,
    appearance,
  };

  return (
    <Modal
      title={"Card"}
      secondaryTitle="Add"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onSuccessButtonVisible: false,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: false,
        onCancelButtonText: "No",
      }}>
      <Elements
        options={options}
        stripe={stripePromise}>
        <CardForm action={{ mode: "cardAdd", cancelbtnFunction: onCancel, submitBtnFunction: onSuccess }} />
      </Elements>
    </Modal>
  );
};

export default CardAddModal;
