import React from "react";
import Checkbox from "src/components/form/Checkbox";
import { formatCurrency } from "src/helpers";

export default function ToggleInterval({ interval, setInterval, plan }) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {plan?.pricing?.rates?.map((price) => {
        let pricePerMonth = ((price?.amount || 0) - (price?.discount_amount || 0)) / 12;
        let totalPrice = price ? price.amount : 0;

        if (price?.interval === "month") {
          pricePerMonth *= 12;
          totalPrice *= 12;
        }

        const discountedPrice = totalPrice - (price?.discount_amount || 0);

        return (
          <div className="relative mt-2 flex w-full flex-col gap-4 rounded-md border p-3">
            {price?.discount_type !== "none" && <div className="absolute right-0 top-0 rounded-bl-lg bg-slate-200 p-1.5 text-sm">{price?.discount_percentage}% OFF</div>}
            <div className="flex gap-2">
              <Checkbox
                isChecked={price?.interval === interval}
                onChange={() => setInterval(price?.interval)}
              />
              <div className="text-lg font-medium capitalize">{price?.interval}ly</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-lg text-yellow-600">
                {formatCurrency(pricePerMonth)}
                <span className="pl-0.5 text-base">/month</span>
              </div>
              <div className="font-medium">
                Total: {formatCurrency(discountedPrice)}/year
                {price?.discount_amount > 0 > 0 && <span className="ml-1 text-slate-500 line-through">{formatCurrency(totalPrice)}/year</span>}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
