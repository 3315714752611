import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";

const PermissionDenied = () => {
  const { workspace_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [navigating, setNavigating] = useState(false);

  const { planDetails } = useContext(WorkspaceContext);

  useEffect(() => {
    // This function will be called whenever the location changes
    const isRedirectedFromRouter = location.state?.from === "react-router-dom";
    const isAnchorTagPress = !location.state;
    const navigationEntries = performance.getEntriesByType("navigation");
    const isReloaded = navigationEntries.length > 0 && navigationEntries[0].type === "reload";

    if (isRedirectedFromRouter) {
      // console.dir('Redirected from React Router DOM');
    } else if (isReloaded) {
      // console.dir('Page was reloaded');
      // navigate("/")
    } else if (isAnchorTagPress) {
      // console.dir('Navigated using anchor tag');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!planDetails?._id && workspace_id) handleRefresh("initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails?._id, workspace_id]);

  const handleRefresh = async (from = "") => {
    try {
      if (from !== "initial") setNavigating(true);
      const { data: resData } = await apiRequest("GET", `/portal/users/${workspace_id}/page`, {}, { returnErrorWithstatus: true });
      if (resData.status === 200) {
        navigate(resData.link);
      } else {
        navigate("/permission-denied");
      }
      setTimeout(() => {
        setNavigating(false);
      }, 500);
    } catch (error) {}
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
        <button
          onClick={handleRefresh}
          disabled={navigating}
          className={classNames("group flex w-[140px] cursor-pointer items-center justify-center rounded-md py-2 shadow-md transition-all duration-200", navigating ? "w-[160px] gap-x-5 bg-gray-50/50" : "w-[120px] bg-gray-50 hover:bg-gray-100 hover:shadow-lg")}>
          <p className={classNames("flex h-full items-center text-2xl transition-all duration-150 ", navigating ? "text-gray-300" : "text-gray-400 group-hover:text-gray-500")}>{navigating ? "Loading..." : "Refresh"}</p>
          <div className={classNames("transition-all duration-300", navigating ? "w-auto opacity-100" : "w-0 opacity-0")}>
            <Preloader
              circleDimension="6"
              text=""
            />
          </div>
        </button>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">{workspace_id ? "No content assigned" : "Sorry, you don't have any access to content yet."}</h2>
        <div className="text-regular mt-1 text-center text-gray-600">Contact us and request access to your pages and analytics.</div>
      </div>
    </div>
  );
};

export default PermissionDenied;
