import { useContext, useEffect, useRef, useState } from "react";
import { WorkspaceContext } from "src/context/workspace";
import { extractAndRemoveScripts, transpileES6ToES5 } from "src/helpers";
import { classNames } from "src/helpers/classNames";

export default function CustomHtmlEmbed({ content }) {
  const { details: workspaceDetails } = useContext(WorkspaceContext);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [srcDoc, setSrcDoc] = useState("");
  const iframeRef = useRef(null);

  useEffect(() => {
    try {
      const { modifiedHtml, extractedScripts } = extractAndRemoveScripts(content.custom_html);

      // Add CSS links to the modified HTML
      const cssLinks = `
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css">
      `;
      const htmlWithCss = modifiedHtml.replace("</head>", `${cssLinks}</head>`);

      // Add scripts to the modified HTML
      const scriptsHtml = extractedScripts
        .reverse()
        .map((script) => {
          const matches = script.match(/<script[^>]*>([\s\S]*?)<\/script>/);
          if (matches && matches[0]) {
            const fullScriptTag = matches[0];
            const scriptContent = matches[1] ? transpileES6ToES5(matches[1]) : "";
            return fullScriptTag.replace(matches[1], scriptContent);
          }
          return "";
        })
        .join("");

      const finalHtml = htmlWithCss.replace("</body>", `${scriptsHtml}</body>`);
      setSrcDoc(finalHtml);
    } catch (error) {
      console.error("Error processing custom HTML:", error);
    }

    const handleIframeLoad = () => {
      setIsIframeLoaded(true);
    };

    const iframeElement = iframeRef.current;
    if (iframeElement) {
      iframeElement.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [content]);

  return (
    <div className={classNames("relative h-full w-full")}>
      <div
        className={"relative z-0 mx-auto h-full w-full overflow-auto "}
        style={{ maxWidth: workspaceDetails?.max_width || "100%" }}>
        <iframe
          ref={iframeRef}
          id="iframe"
          title="Custom Html Embed"
          srcDoc={srcDoc}
          width="100%"
          height="100%"
          sandbox="allow-scripts allow-same-origin allow-downloads"
          style={{
            visibility: isIframeLoaded ? "visible" : "hidden",
            border: "none",
            margin: "0",
          }}
        />
      </div>
    </div>
  );
}
