import React, { useState } from "react";
import moment from "moment";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import { ForwardIcon } from "@heroicons/react/24/outline";
import Toggle from "src/components/form/Toggle";
import Button from "src/components/form/Button";
import Modal from "src/components/shared/Modal";
import ToggleHeader from "src/components/form/ToggleHeader";
import ToggleInterval from "./ToggleInterval";

const PlanResubscribe = ({ onHold, activePlan, interval, setInterval, workspaceDetails, setPaymentDetails, setOnHold, setView }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resubscribeConfirmation, setResubscribeConfirmation] = useState(false);

  const handleSubscribe = async () => {
    try {
      setButtonLoading(true);
      const response = await apiRequest("post", `/portal/workspaces/:workspace_id/billing/payment/intent`, { body: { interval: activePlan?.subscription?.recurring_type }, params: { workspace_id: workspaceDetails?._id } });
      if (response?.data?.status === 200) {
        setResubscribeConfirmation(false);
        setPaymentDetails(response?.data?.data);
        setView("payForm");
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between border-b px-2 py-1 text-base font-normal text-slate-600">
        {activePlan?.subscription_status === "on_trial" && `Your free trial will expire in ${activePlan?.free_trial_days_left} day(s). To avoid automatic expiration, please complete your payment.`}
        {activePlan?.subscription_status === "canceled" && (
          <div>
            Your subscription has been canceled. Your current plan will expire on <span className="font-medium text-highlightColor">{moment(activePlan?.end_date).format("MMM DD, YYYY")}</span>, To continue, please renew your subscription.
          </div>
        )}

        {activePlan?.subscription_status === "expired" && `Your subscription has expired. To regain access, please renew your subscription.`}
      </div>
      <div className="mt-2">
        <ToggleInterval
          plan={activePlan?.plan_details}
          interval={interval}
          setInterval={setInterval}
        />
      </div>
      <div className="mt-4 flex justify-end pt-3">
        <Button
          isDisable={buttonLoading}
          buttonClasses="!bg-highlightColor"
          buttonIconPosition={["canceled"].includes(activePlan?.subscription_status) ? "" : "right"}
          buttonIcon={ForwardIcon}
          buttonLabel={["canceled"].includes(activePlan?.subscription_status) ? "Resubscribe" : "Proceed to Payment"}
          buttonLabelClasses={"!font-medium !text-base"}
          buttonFunction={["canceled"].includes(activePlan?.subscription_status) ? () => setResubscribeConfirmation(true) : () => handleSubscribe()}
        />
      </div>
      <ResubscribeModal
        isOpen={resubscribeConfirmation}
        disable={buttonLoading}
        onHold={onHold}
        endDate={moment(activePlan?.end_date).add(1, "days").format("MMM DD, YYYY")}
        setOnHold={setOnHold}
        onDelete={() => {
          handleSubscribe();
        }}
        onCancel={() => setResubscribeConfirmation(false)}
      />
    </div>
  );
};

export default PlanResubscribe;

const ResubscribeModal = ({ endDate, setOnHold, onHold, isOpen = false, disable = false, onDelete = () => {}, onCancel = () => {} }) => {
  return (
    <Modal
      title={"Resubscribe"}
      secondaryTitle="Confirmation"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onDelete}
      defaultOptions={{
        onSuccessButtonText: "Confirm",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
        onCancelButtonVisible: true,
        onCancelButtonText: "Cancel",
      }}>
      <div className="flex items-center gap-2 ">
        <ToggleHeader
          title={`Activate New Plan Immediately`}
          subtitle={
            <div>
              Turn this switch ON to activate the new plan immediately. If left OFF, the new plan will start automatically at <span className="font-semibold text-highlightColor">{endDate}</span> after the end of your current billing period .
            </div>
          }>
          <Toggle
            checked={onHold}
            onChange={(e) => {
              setOnHold((pre) => !pre);
            }}
          />
        </ToggleHeader>
      </div>
    </Modal>
  );
};
