import React, { useCallback, useEffect, useState } from "react";
import Table from "src/components/Table/Table";
import TRHeader from "src/components/Table/TRHeader";
import InvoicesEntry from "./InvoicesEntry";
import { apiRequest } from "src/async/apiUtils";
import PaginationFooter from "src/components/Table/PaginationFooter";

const Invoices = ({ workspaceId }) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [meta, setMeta] = useState();
  const [invoices, setInvoices] = useState([]);
  const [invoicesLoaded, setInvoiceLoaded] = useState(false);

  const getInvoicesList = useCallback(async () => {
    try {
      setInvoiceLoaded(false);
      setInvoices([]);

      const res = await apiRequest("get", `portal/workspaces/:workspace_id/billing/transactions`, {
        queries: { limit: limit, offset: offset },
        params: { workspace_id: workspaceId },
      });

      if (res.data.status === 200) {
        setInvoices(res.data.data || []);
        setMeta(res.data.meta);
      } else {
        setInvoices([]);
      }
      setInvoiceLoaded(true);
    } catch (error) {
      console.error(error);
    } finally {
      setInvoiceLoaded(true);
    }
  }, [workspaceId, limit, offset]);

  useEffect(() => {
    getInvoicesList();
  }, [getInvoicesList]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  return (
    <div className="relative space-y-6 pb-6">
      <div
        className="text-xl font-semibold"
        caption="Any changes will be applied to your upcoming invoices.">
        Invoices
      </div>
      <div className="relative">
        <Table
          tableHeader={
            <TRHeader>
              <th
                scope="col"
                className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
                Date
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Payment Method
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Amount
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-end text-sm font-semibold text-gray-900 sm:table-cell">
                Action
              </th>
            </TRHeader>
          }
          colSpan="5"
          isPreloader={false}
          loaded={invoicesLoaded}
          dataExists={invoices?.length > 0}>
          {invoices?.map((item, index) => (
            <InvoicesEntry
              key={index}
              data={item}
            />
          ))}
        </Table>
        <PaginationFooter
          itemName="Invoices record"
          limit={limit}
          offset={offset}
          count={meta?.count}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default Invoices;
