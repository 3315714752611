import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { formatCurrency } from "src/helpers";
import { CardForm } from "../stripeForm/CardForm";

const CardFormWithStripe = ({ onHold, interval, setView = () => {}, paymentDetails, workspaceDetails, planDetails, refresh = () => {} }) => {
  const stripePromise = loadStripe(paymentDetails?.stripe_publishable_key);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: paymentDetails?.client_secret,
    appearance,
  };

  return (
    <div className="mx-auto h-full w-full bg-white p-4">
      <PlanToPay
        planDetails={planDetails}
        interval={interval}
      />
      <Elements
        options={options}
        stripe={stripePromise}>
        <CardForm
          action={{ mode: "payment", cancelbtnFunction: () => setView(planDetails?.active_plan?.subscription_status !== "pending" ? "activePlan" : "plan"), isOnholdPlan: planDetails?.active_plan?.upcoming_plan?.payment_status === "pending" }}
          refresh={refresh}
          onHold={onHold}
          interval={interval}
          paymentDetails={paymentDetails}
          workspaceDetails={workspaceDetails}
        />
      </Elements>
    </div>
  );
};

export default CardFormWithStripe;

const PlanToPay = ({ planDetails, interval }) => {
  return (
    <>
      <div>
        <div className="flex w-full items-center gap-3 space-x-2 text-2xl font-semibold">{planDetails?.plan?.name || "Plan Name"}</div>
        <div className="mb-4 py-1 pl-0.5 text-[13px] font-medium text-gray-500">{planDetails?.plan?.description || "No description provided."}</div>
      </div>
      <div className="flex items-center justify-between gap-1 border-b border-t">
        <div className=" rounded-md border border-gray-200 px-3 py-1 font-bold text-highlightColor">
          {planDetails?.plan?.pricing?.rates?.map((rate) => {
            if (rate.interval !== interval) return null;
            const discountedPrice = rate.amount - rate.discount_amount;
            const showDiscount = rate.discount_type !== "none" && rate.discount_percentage > 0;
            return (
              <div
                key={rate._id}
                className="flex items-center justify-between">
                <div>
                  {showDiscount ? (
                    <>
                      <span className="text-gray-400 line-through">{formatCurrency(rate.amount)}</span>
                      <span className="ml-2 text-green-600">{formatCurrency(discountedPrice)}</span>
                    </>
                  ) : (
                    <span>{formatCurrency(rate.amount)}</span>
                  )}
                  <span className="text-base font-medium text-gray-400"> / {rate.interval}</span>
                </div>
                {showDiscount && <span className="ml-2 rounded bg-yellow-100 px-2 py-0.5 text-sm font-medium text-yellow-800">{rate.discount_percentage}% off</span>}
              </div>
            );
          })}
        </div>
        <div className="my-3 flex  items-center justify-between px-2 text-lg font-semibold text-slate-600">
          <div>Amount Payable : </div>
          <div className="text-highlightColor">{`${formatCurrency(planDetails?.plan?.pricing?.rates?.find((rate) => rate?.interval === interval)?.amount - planDetails?.plan?.pricing?.rates?.find((rate) => rate?.interval === interval)?.discount_amount || 0)}`}</div>
        </div>
      </div>
      {planDetails?.plan?.free_trial?.status && !planDetails?.active_plan?.subscription_status === "on_trial" && (
        <div className="space-y-2">
          <div className="rounded-md border border-blue-100 bg-blue-50 px-4 py-2">
            <span className="text-sm font-medium text-blue-600">Free Trial:</span>
            <span className="ml-2 text-lg font-semibold text-blue-800">{planDetails?.plan?.free_trial?.days} days</span>
            <p className="mt-1 text-sm text-blue-700">This plan includes a free trial of {planDetails?.plan?.free_trial?.days} days. After the trial ends, your subscription will continue automatically.</p>
          </div>
        </div>
      )}
    </>
  );
};
