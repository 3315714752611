import CardFormWithStripe from "src/components/payments/CardFormWithStripe";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import AssignedPlan from "src/components/payments/AssignedPlan";
import React, { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "src/context/workspace";
import Overview from "src/components/payments/paymentTabs/overview/Overview";
import Tabs from "src/components/Tabs/Tabs";
import Invoices from "src/components/payments/paymentTabs/invoices/Invoices";
import PaymentMethods from "src/components/payments/paymentTabs/paymentMethods/PaymentMethods";
import InformationAlert from "src/components/shared/Alerts/InformationAlert";

const ManagePayments = ({ loading, loadPlanDetails }) => {
  const { details: workspaceDetails, planDetails } = useContext(WorkspaceContext);

  const [interval, setInterval] = useState("year");
  const [onHold, setOnHold] = useState(false);
  const [view, setView] = useState("plan");
  const [paymentDetails, setPaymentDetails] = useState({});
  const [cardAdded, setCardAdded] = useState(planDetails?.stripe_credential_changed);

  useEffect(() => {
    if (planDetails?.active_plan && planDetails?.active_plan?.subscription_status === "pending") {
      setView("plan");
    } else {
      setView("activePlan");
    }
  }, [planDetails]);

  const tabs = [
    {
      name: "Overview",
      component: (
        <Overview
          onHold={onHold}
          setView={setView}
          interval={interval}
          setOnHold={setOnHold}
          setInterval={setInterval}
          loadPlanDetails={loadPlanDetails}
          activePlan={planDetails?.active_plan}
          setPaymentDetails={setPaymentDetails}
        />
      ),
    },
    {
      name: "Invoices",
      component: <Invoices workspaceId={workspaceDetails?._id} />,
    },
    {
      name: "Payment Menthod",
      component: (
        <PaymentMethods
          workspaceId={workspaceDetails?._id}
          setCardAdded={setCardAdded}
        />
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <div className="flex min-h-[300px] w-full items-center justify-center">
          <Preloader />
        </div>
      ) : (
        <>
          {planDetails ? (
            <>
              <div className="h-full min-h-[350px] w-full gap-5  ">
                {cardAdded && (
                  <div className="pb-2">
                    <InformationAlert
                      content={
                        <div className="relative   text-sm">
                          <span className="inline">
                            The stripe credentials are changed, due to which all your card details has been deleted, so add atleast one card{" "}
                            {planDetails?.active_plan?.subscription_status === "active" ? " to continue the subscription or the current plan will expire after next billing date" : ""}.
                          </span>
                        </div>
                      }
                      type="warning"
                    />
                  </div>
                )}
                {view === "plan" ? (
                  <>
                    {planDetails?.active_plan && ["pending"].includes(planDetails?.active_plan?.subscription_status) && (
                      <AssignedPlan
                        setView={setView}
                        interval={interval}
                        setInterval={setInterval}
                        setPaymentDetails={setPaymentDetails}
                        workspaceDetails={workspaceDetails}
                        activePlan={planDetails?.active_plan}
                        plan={planDetails?.active_plan?.plan_details}
                      />
                    )}
                  </>
                ) : view === "payForm" ? (
                  <CardFormWithStripe
                    setView={setView}
                    onHold={onHold}
                    interval={interval}
                    refresh={loadPlanDetails}
                    planDetails={planDetails}
                    paymentDetails={paymentDetails}
                    workspaceDetails={workspaceDetails}
                  />
                ) : (
                  view === "activePlan" &&
                  planDetails?.active_plan &&
                  planDetails?.active_plan?.subscription_status !== "pending" && (
                    <div className="w-full">
                      <Tabs tabs={tabs} />
                    </div>
                  )
                )}
              </div>
            </>
          ) : (
            <div className="flex w-full flex-col items-center justify-center px-2 py-12 sm:px-6 lg:px-8">
              <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">This site is currently down .</h2>
                <div className="text-regular mt-1 text-center text-gray-600">Please contact your administrator for more details</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ManagePayments;
