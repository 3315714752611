import SelectMenu from "src/components/form/SelectMenu";
import Modal from "src/components/shared/Modal";

const CardEditModal = ({ isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {}, selectedCard, setSelectedCard }) => {
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    key: (i + 1).toString().padStart(2, "0"),
    value: i + 1,
  }));

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 30 }, (_, i) => ({
    key: (currentYear + i).toString(),
    value: currentYear + i,
  }));

  return (
    <Modal
      title={"Card"}
      secondaryTitle="Edit"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Update",
        onSuccessButtonVisible: true,
        onSuccessLoaderStart: disable,
        onSuccessLoaderVisible: true,
        onCancelButtonVisible: true,
        onCancelButtonText: "Cancel",
      }}>
      <div className="grid grid-cols-2 gap-4">
        <div className="relative">
          <SelectMenu
            label={"Expiry Month"}
            startIndex={monthOptions?.findIndex((type) => type?.value === selectedCard?.exp_month)}
            options={monthOptions}
            setOption={(option) => {
              setSelectedCard((pre) => ({ ...pre, exp_month: option?.value }));
            }}
          />
        </div>
        <div className="relative">
          <SelectMenu
            startIndex={yearOptions?.findIndex((type) => type?.value === selectedCard?.exp_year)}
            label={"Expiry Year"}
            options={yearOptions}
            setOption={(option) => {
              setSelectedCard((pre) => ({ ...pre, exp_year: option?.value }));
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CardEditModal;
