const NoData = ({ title = "No data found", type = "", image = { icon: null } }) => {
  return (
    <div className="flex h-[120px] w-full items-center justify-center gap-x-4 rounded-md bg-white">
      {image?.icon && <image.icon className="h-5 w-5" />}
      <p className="text-md m-0 px-6 py-3 font-medium text-gray-300">{type ? `No ${type} found` : title}</p>
    </div>
  );
};

export default NoData;
