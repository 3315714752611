import { Menu, Transition } from "@headlessui/react";
import { ArrowUturnLeftIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { bottom, left, right, top } from "@popperjs/core";
import { Fragment, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { classNames } from "src/helpers/classNames";
import Button from "../form/Button";

const QuickMenu = ({ disabled = false, items = [], icon = null, xPlacement = "bottomRight", buttonClass = "", flip = true }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [viewToggle, setViewToggle] = useState(false);
  const [viewContent, setViewContent] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || "bottomLeft"],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
      !flip && {
        name: "flip",
        options: {
          flipVariations: false,
          fallbackPlacements: ["bottom-end"],
        },
      },
    ],
  });

  return (
    <>
      {disabled ? (
        <div className="flex max-w-xs items-center rounded-full text-sm focus:outline-none">
          <span className="sr-only">Open menu</span>
          {icon ? (
            icon
          ) : (
            <div className={classNames("flex cursor-not-allowed items-center justify-center rounded-full px-1 py-3 text-gray-400 transition-colors duration-100", buttonClass)}>
              <EllipsisVerticalIcon className="h-5 w-5" />
            </div>
          )}
        </div>
      ) : (
        <Menu
          as="div"
          className="relative">
          <div>
            <Menu.Button
              ref={setTargetElement}
              className="flex max-w-xs items-center text-sm focus:outline-none">
              <span className="sr-only">Open menu</span>
              {icon ? (
                icon
              ) : (
                <div className={classNames("flex items-center justify-center rounded-full px-1 py-3 text-gray-800 transition-colors duration-100 hover:bg-gray-100", buttonClass)}>
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </div>
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            className="z-50"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => {
              setPopperElement(null);
              setViewToggle(false);
              setViewContent(null);
            }}
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}>
            <Menu.Items className={classNames(`absolute mt-1 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`, viewToggle ? `w-80` : `w-40`)}>
              {viewToggle ? (
                <div className="relative">
                  <div className="border-b border-gray-200 px-4 py-1">
                    <Button
                      buttonClasses={"!h-7 !p-1 !text-sm bg-highlightColor"}
                      buttonIconPosition={"left"}
                      buttonIcon={ArrowUturnLeftIcon}
                      buttonLabel={"Back"}
                      buttonFunction={() => {
                        setViewToggle(false);
                        setViewContent(null);
                      }}
                    />
                  </div>
                  <div className="px-4 py-2">{viewContent}</div>
                </div>
              ) : (
                <div>
                  {items.map((item, i) =>
                    item.type === "hr" ? (
                      <div
                        key={"hr-" + i}
                        className={classNames(item?.hideWhen ? "hidden" : "", "my-1 h-[1px] w-full bg-gray-100")}></div>
                    ) : (
                      <Menu.Item key={item.name}>
                        <Button
                          buttonClasses={classNames("!flex !h-10", item?.hideWhen ? "!hidden" : "", "relative flex w-full  py-2 text-left text-sm", item.color ? item.color : "text-gray-700 hover:text-highlightColor")}
                          buttonIconPosition={"left"}
                          buttonIconClass={item?.buttonIconClass}
                          buttonIcon={item.icon ? item.icon : ArrowUturnLeftIcon}
                          buttonLabel={item.name}
                          isDisable={item?.disabled || false}
                          buttonFunction={(e) => {
                            if (item?.component) {
                              e.preventDefault();
                              setViewToggle(true);
                              setViewContent(item?.component);
                            } else {
                              item.onClick();
                            }
                          }}
                        />
                      </Menu.Item>
                    ),
                  )}
                </div>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
};

export default QuickMenu;
