import { createContext } from "react";

export const WorkspaceContext = createContext({
  loading: true,
  list: [],
  setList: () => {},
  details: {},
  setDetails: () => {},
  setLoading: () => {},
  menuType: "vertical",
  workspacePaymentModalOpen: false,
  setWorspacePaymentModalOpen: () => {},
  isPlanDetailsLoaded: false,
  setIsPlanDetailsLoaded: () => {},
  params: { workspace_id: null, group_id: null, page_id: null },
});
