import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";
import BlurImage from "src/assets/images/blur_image.png";
import Image from "../elements/Image";

const CustomModal = ({ title, hideImage = false, secondaryTitle = "", isOpen = false, size = "md", onCancel = () => {}, onClose = null, children, hideCrossIcon = false }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed left-0 top-16 z-20 flex h-[calc(100vh-4rem)] w-full overflow-hidden py-8 ">
      <div className={classNames("absolute left-0 top-0 z-[-1] h-full w-full", hideImage ? "bg-black opacity-50" : "")}>
        {!hideImage && (
          <Image
            src={BlurImage}
            effect={"blur"}
            alt="blur_img"
            classes="h-full w-full object-cover"
          />
        )}
      </div>
      <div className={classNames("relative mx-auto my-auto w-full max-w-5xl transform rounded-lg bg-white shadow-lg")}>
        <div className="flex h-16 flex-shrink-0 items-center justify-between border-b px-6">
          {title && (
            <h3 className="text-xl font-semibold text-gray-900">
              {title} {secondaryTitle && <span className="text-gray-400">/ {secondaryTitle}</span>}
            </h3>
          )}
          {!hideCrossIcon && (
            <button
              type="button"
              className="ml-auto text-gray-400 hover:text-gray-200 focus:outline-none"
              onClick={onClose ? onClose : onCancel}>
              <XMarkIcon className="h-6 w-6" />
            </button>
          )}
        </div>
        <div className={`scrollbar max-h-[calc(100vh-12rem)] flex-shrink flex-grow overflow-y-auto overflow-x-hidden p-4 pt-2`}>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
