import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "../form/Button";
import { ArrowUturnLeftIcon, BanknotesIcon, CreditCardIcon, XMarkIcon } from "@heroicons/react/24/outline";
import usePaymentMethods from "src/hooks/usePaymentMethods";
import SelectMenu from "../form/SelectMenu";
import moment from "moment";
import { cardtypes } from "src/helpers/planHelper";
import Preloader from "../shared/LoadingAnimations/Preloader";

export const CardForm = ({ onHold, interval, workspaceDetails, paymentDetails, refresh = () => {}, action = { mode: "payment", cancelbtnFunction: () => {}, submitBtnFunction: () => {} } }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { paymentMethods, paymentMethodLoaded } = usePaymentMethods(workspaceDetails?._id);
  const [selectedCard, setSelectedCard] = useState("");
  const [isNewCard, setIsNewCard] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const cards = paymentMethods?.map((card) => ({
    value: card?.card_id,
    default: card?.default,
    Component: () => {
      return (
        <div className="flex items-center justify-between pr-5">
          <div className="flex items-center gap-3">
            <div
              className="w-10"
              dangerouslySetInnerHTML={{ __html: card?.brand ? cardtypes[card?.brand] : "" }}></div>
            <div className="font-medium text-gray-900">{`XXXX-XXXX-XXXX-${card?.last4}`}</div> {card?.default && <div className="flex h-6 w-16 items-center justify-center rounded-md border bg-blue-100 font-medium text-blue-500">Default</div>}
          </div>
          <div className="flex  font-medium">
            <span className="text-gray-800">{moment(`${card?.exp_year}-${card?.exp_month}-01`).format("MMM, YYYY")}</span>
          </div>
        </div>
      );
    },
  }));

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);

      let confirmError, paymentIntent;

      if (!selectedCard) {
        if (paymentDetails?.mode === "payment_intent") {
          const response = await stripe.confirmPayment({
            elements: elements,
            redirect: "if_required",
          });
          confirmError = response.error;
          paymentIntent = response.paymentIntent;
        } else {
          const response = await stripe.confirmSetup({
            elements: elements,
            confirmParams: {
              return_url: ``,
              payment_method_data: {
                billing_details: {
                  // name: paymentDetails?.name,
                },
              },
            },
            redirect: "if_required",
          });
          confirmError = response.error;
          paymentIntent = response.setupIntent;
        }
      }

      if (confirmError) {
        toast.error(confirmError.message);
        setIsSubmitting(false);
        return;
      }

      if (!selectedCard && paymentIntent.status !== "succeeded") {
        toast.error("Payment status unknown.");
        setIsSubmitting(false);
        return;
      }

      const body = action?.mode === "payment" ? { payment_method: selectedCard || paymentIntent?.payment_method, payment_id: paymentIntent?.id, interval, on_hold: onHold ? false : true, existing_card: selectedCard ? true : false } : {};

      if (action?.mode === "payment") {
        const response = await apiRequest(action?.isOnholdPlan ? "PUT" : "POST", `portal/workspaces/:workspace_id/billing/plan/${action?.isOnholdPlan ? "active" : "purchase"}`, {
          body: body,
          params: { workspace_id: workspaceDetails?._id },
        });

        toast.success(response?.data?.message);
        refresh(workspaceDetails?._id);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        action?.submitBtnFunction();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setIsNewCard(paymentMethodLoaded && paymentMethods?.length === 0 ? true : false);

    if (paymentMethods?.length > 0) {
      setSelectedCard(paymentMethods?.find((card) => card?.default)?.card_id || paymentMethods[0]?.card_id);
    }
  }, [paymentMethods, paymentMethodLoaded]);

  return (
    <div className="w-full space-y-6">
      {!paymentMethodLoaded && action?.mode === "payment" && (
        <div className="my-2">
          <Preloader />
        </div>
      )}
      {action?.mode === "payment" && paymentMethods?.length > 0 && (
        <div className="mt-3 grid w-full grid-cols-6 items-end gap-2">
          {!isNewCard && (
            <div className="relative col-span-4">
              <SelectMenu
                label={"Choose a Card"}
                dropdownClass={"!w-full"}
                startIndex={cards?.findIndex((type) => type?.value === selectedCard)}
                options={cards}
                setOption={(option) => {
                  setSelectedCard(option?.value);
                }}
              />
            </div>
          )}
          <div className="relative col-span-2">
            <Button
              isDisable={isSubmitting}
              buttonClasses={`!bg-highlightColor !w-full`}
              buttonIconPosition={"left"}
              buttonIcon={CreditCardIcon}
              buttonLabel={isNewCard ? "Choose a Card" : "New Card"}
              buttonFunction={() => setIsNewCard(!isNewCard)}
            />
          </div>
        </div>
      )}
      <div className="mt-3 space-y-4">
        {((isNewCard && action?.mode === "payment") || action?.mode === "cardAdd") && (
          <div className="relative">
            <PaymentElement />
          </div>
        )}
        <div className="relative flex w-full items-center justify-center gap-2">
          <Button
            isDisable={isSubmitting || (action?.mode === "payment" && !paymentMethodLoaded)}
            buttonClasses={`${action?.mode === "payment" ? "!w-1/12" : "!w-1/2"} !bg-white !text-highlightColor border !border-highlightColor`}
            buttonIconPosition={"left"}
            buttonIcon={action?.mode === "payment" ? ArrowUturnLeftIcon : XMarkIcon}
            buttonLabel={action?.mode === "payment" ? "" : "Cancel"}
            buttonFunction={action?.cancelbtnFunction}
          />
          <Button
            isDisable={isSubmitting || (action?.mode === "payment" && !paymentMethodLoaded)}
            buttonClasses={`!bg-highlightColor ${action?.mode === "payment" ? "!w-11/12" : "!w-1/2"}`}
            buttonIconPosition={"left"}
            buttonIcon={action?.mode === "payment" ? BanknotesIcon : CreditCardIcon}
            buttonLabel={action?.mode === "payment" ? "Pay Now" : "Add Card"}
            buttonFunction={onHandleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
