const { useState, useCallback, useEffect } = require("react");
const { apiRequest } = require("src/async/apiUtils");

export default function usePaymentMethods(workspace_id, setCardAdded) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);

  const getPaymentMethodList = useCallback(async () => {
    try {
      setPaymentMethodLoaded(false);
      setPaymentMethods([]);

      const res = await apiRequest("get", `portal/workspaces/:workspace_id/billing/payment-methods`, {
        params: { workspace_id: workspace_id },
      });

      if (res.data.status === 200) {
        setPaymentMethods(res.data.data || []);
        if (res.data.data?.length > 0) setCardAdded(false);
      } else {
        setPaymentMethods([]);
      }
      setPaymentMethodLoaded(true);
    } catch (error) {
    } finally {
      setPaymentMethodLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id]);

  useEffect(() => {
    getPaymentMethodList();
  }, [getPaymentMethodList]);

  return {
    paymentMethods,
    paymentMethodLoaded,
    refresh: getPaymentMethodList,
  };
}
