import React, { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import Table from "src/components/Table/Table";
import TRHeader from "src/components/Table/TRHeader";
import PaymentMethodEntry from "./PaymentMethodEntry";
import Button from "src/components/form/Button";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import CardAddModal from "./CardAddModal";
import CardDeleteModal from "./CardDeleteModal";
import CardEditModal from "./CardEditModal";
import usePaymentMethods from "src/hooks/usePaymentMethods";

const PaymentMethod = ({ workspaceId, setCardAdded = () => {} }) => {
  const { paymentMethods, paymentMethodLoaded, refresh: getPaymentMethodList } = usePaymentMethods(workspaceId, setCardAdded);
  const [cardAddModalOpen, setCardAddModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDefaultLoading, setIsDefaultLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleAddModalOpen = async () => {
    try {
      setIsButtonDisabled(true);
      const res = await apiRequest("post", `portal/workspaces/:workspace_id/billing/card/intent`, { params: { workspace_id: workspaceId } });

      if (res?.data?.status === 200) {
        setPaymentDetails(res?.data?.data);
        setCardAddModalOpen(true);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleCardDelete = async () => {
    try {
      setIsDeleting(true);
      const res = await apiRequest("delete", `portal/workspaces/:workspace_id/billing/card/:card_id`, { params: { workspace_id: workspaceId, card_id: selectedCard?.card_id } });

      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        getPaymentMethodList();
        setDeleteModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleMakeCardDefault = async (data) => {
    try {
      setIsDefaultLoading(true);
      const res = await apiRequest("post", `portal/workspaces/:workspace_id/billing/card/:card_id/default`, { params: { workspace_id: workspaceId, card_id: data?.card_id } });

      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        getPaymentMethodList();
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsDefaultLoading(false);
    }
  };

  const handleEditCard = async () => {
    try {
      setIsEditing(true);
      const res = await apiRequest("put", `portal/workspaces/:workspace_id/billing/card/:card_id`, {
        body: {
          exp_month: selectedCard?.exp_month,
          exp_year: selectedCard?.exp_year,
        },
        params: { workspace_id: workspaceId, card_id: selectedCard?.card_id },
      });

      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        getPaymentMethodList();
        setEditModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsEditing(false);
    }
  };

  const hanldleCardAdd = () => {
    getPaymentMethodList();
    setCardAddModalOpen(false);
  };

  const handleSelectCard = (card) => {
    setSelectedCard(card);
  };

  return (
    <div className="relative space-y-6 pb-6">
      <div className="flex w-full items-center justify-between">
        <div className="text-xl font-semibold">Payment Methods</div>
        <Button
          isDisable={isButtonDisabled || !paymentMethodLoaded}
          buttonClasses={`!bg-highlightColor`}
          buttonIconPosition={"left"}
          buttonIcon={CreditCardIcon}
          buttonLabel={"Add Card"}
          buttonFunction={handleAddModalOpen}
        />
      </div>
      <div className="relative">
        <Table
          tableHeader={
            <TRHeader>
              <th
                scope="col"
                className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
                Type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Particulars
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                Card Expires
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-end text-sm font-semibold text-gray-900 sm:table-cell">
                Action
              </th>
            </TRHeader>
          }
          colSpan="5"
          loaded={paymentMethodLoaded}
          isPreloader={false}
          dataExists={paymentMethods?.length > 0}>
          {paymentMethods?.map((item) => (
            <PaymentMethodEntry
              data={{
                ...item,
                deleteFunction: () => {
                  setDeleteModalOpen(true);
                },
                defaultFunction: handleMakeCardDefault,
                selectCardFunction: handleSelectCard,
                isDefaultLoading: isDefaultLoading,
                editModalFunction: () => setEditModalOpen(true),
              }}
            />
          ))}
        </Table>
      </div>
      <CardAddModal
        isOpen={cardAddModalOpen}
        onSuccess={hanldleCardAdd}
        onCancel={() => setCardAddModalOpen(false)}
        paymentDetails={paymentDetails}
      />
      <CardDeleteModal
        disable={isDeleting}
        isOpen={deleteModalOpen}
        onSuccess={handleCardDelete}
        onCancel={() => setDeleteModalOpen(false)}
      />
      <CardEditModal
        disable={isEditing}
        isOpen={editModalOpen}
        onSuccess={handleEditCard}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        onCancel={() => setEditModalOpen(false)}
      />
    </div>
  );
};

export default PaymentMethod;
