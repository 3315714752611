import { useState, useEffect } from "react";

const useCheckThirdPartyCookies = () => {
  const [thirdPartyCookiesEnabled, setThirdPartyCookiesEnabled] = useState(false);
  const [cookieCheckComplete, setCookieCheckComplete] = useState(false);

  useEffect(() => {
    const checkThirdPartyCookies = async () => {
      try {
        // Create a test iframe
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";

        // Get the current origin for the parent parameter
        const currentOrigin = window.location.origin;

        // Construct the URL with the parent origin as a parameter
        const cookieCheckUrl = new URL("https://cookie-check-app-9xeay.ondigitalocean.app");
        cookieCheckUrl.searchParams.set("parent", currentOrigin);

        iframe.src = cookieCheckUrl.toString();
        document.body.appendChild(iframe);

        const checkPromise = new Promise((resolve) => {
          const messageHandler = (event) => {
            // Verify the origin is from our cookie check domain
            if (event.origin !== "https://cookie-check-app-9xeay.ondigitalocean.app") return;
            if (event.data && event.data.type === "COOKIE_CHECK_RESULT") {
              window.removeEventListener("message", messageHandler);
              setThirdPartyCookiesEnabled(event.data.hasSupport);
              setCookieCheckComplete(true);
              resolve();
            }
          };

          window.addEventListener("message", messageHandler, { passive: true });

          // Set timeout for the check
          setTimeout(() => {
            window.removeEventListener("message", messageHandler);
            setThirdPartyCookiesEnabled(true);
            setCookieCheckComplete(true);
            resolve();
          }, 5000);
        });

        await checkPromise;

        // Clean up iframe
        if (document.body.contains(iframe)) {
          document.body.removeChild(iframe);
        }
      } catch (error) {
        console.error("Cookie check failed:", error);
        setThirdPartyCookiesEnabled(true);
        setCookieCheckComplete(true);
      }
    };

    checkThirdPartyCookies();
  }, []);

  return { thirdPartyCookiesEnabled, cookieCheckComplete };
};

export default useCheckThirdPartyCookies;
