import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Link, Image } from "@react-pdf/renderer";
import moment from "moment";
import { formatCurrency } from "src/helpers";
import LatoRegular from "../../../../assets/fonts/Lato-Regular.ttf";
import LatoBold from "../../../../assets/fonts/Lato-Bold.ttf";
import LatoBlack from "../../../../assets/fonts/Lato-Black.ttf";

Font.register({ family: "LatoRegular", src: LatoRegular });
Font.register({ family: "LatoBold", src: LatoBold });
Font.register({ family: "LatoBlack", src: LatoBlack });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 24,
    fontFamily: "LatoRegular",
  },
});

const statusText = {
  pending: {
    title: "Your payment is pending!",
  },
  succeeded: {
    title: "Thank You for Your Payment!",
  },
  refunded: {
    title: "Your payment has been refunded!",
  },
};

const InvoicePDF = ({ data }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: 20 }}>
          <View style={{ marginBottom: 10 }}>
            {data?.logo && (
              <Image
                src={data?.logo}
                style={{ height: 40, marginBottom: 10 }}
              />
            )}
            <Text style={{ fontSize: 13, fontFamily: "LatoBold" }}>Invoice</Text>
            <Text style={{ fontSize: 13, fontFamily: "LatoBold" }}>{data?.workspaceName}</Text>
          </View>
          <View style={{ position: "relative" }}>
            <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: 11, fontFamily: "LatoBold" }}>Invoice Number:</Text>
              <Text style={{ fontSize: 10, color: "#64748b" }}>{data?.invoice_id}</Text>
            </View>
            <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: 11, fontFamily: "LatoBold" }}>Invoice Date:</Text>
              <Text style={{ fontSize: 10, color: "#64748b" }}>{moment(data?.created_at).format("MMM DD, YYYY")}</Text>
            </View>
            <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: "11px", fontFamily: "LatoBold", textTransform: "capitalize" }}>Customer Name:</Text>
              <Text style={{ fontSize: "10px", color: "#64748b" }}>{data?.user?.name}</Text>
            </View>
            <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "flex-end" }}>
              <Text style={{ fontSize: "11px", fontFamily: "LatoBold", textTransform: "capitalize" }}>Customer Email:</Text>
              <Text style={{ fontSize: "10px", color: "#64748b" }}>{data?.user?.email}</Text>
            </View>
          </View>
        </View>
        {/* Plan Summary  */}
        <View style={{ paddingVertical: 20, borderTopWidth: 0.5, borderTopColor: "#cbd5e1", borderStyle: "solid", width: "100%" }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={{ fontSize: 16, fontFamily: "LatoBold", textTransform: "Capitalize" }}>Summary</Text>
          </View>
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 12, overflow: "hidden" }}>
            <View style={{ flexDirection: "row", backgroundColor: "#f3f4f6", overflow: "hidden", borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
              <View style={{ flexDirection: "row", alignItems: "center", flexWrap: "wrap", width: "60%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }}>
                <Text style={{ fontFamily: "LatoBold", fontSize: 10, color: "#000", textTransform: "capitalize" }}>Plan Name</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", flexWrap: "wrap", width: "23%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }}>
                <Text style={{ fontFamily: "LatoBold", fontSize: 10, color: "#000", textTransform: "capitalize" }}>Payment status</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", flexWrap: "wrap", width: "13%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }}>
                <Text style={{ fontFamily: "LatoBold", fontSize: 10, color: "#000", textTransform: "capitalize" }}>total</Text>
              </View>
            </View>
            <View style={{ width: "100%", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, backgroundColor: "white" }}>
              <View
                style={{ display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid #e2e8f0" }}
                wrap={false}>
                <View style={{ width: "60%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }}>
                  <Text style={{ fontSize: 9 }}>{data?.plan_details?.name}</Text>
                </View>
                <View style={{ width: "23%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, textTransform: "capitalize" }}>
                  <Text style={{ fontSize: 9 }}>{data?.payment_status}</Text>
                </View>
                <View style={{ width: "13%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }}>
                  <Text style={{ fontSize: 9 }}>{formatCurrency(data?.amount)}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Adjusted Summary  */}
        {data?.adjusted_data?.isAdjust && (
          <View>
            <View style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 16, fontFamily: "LatoBold", textTransform: "Capitalize" }}>Adjusted Summary</Text>
            </View>
            <View style={{ marginBottom: 20, display: "flex", border: "1px solid #e2e8f0", borderRadius: 12, color: "#212121", paddingRight: 8, backgroundColor: "#FFF" }}>
              <View style={{ flexShrink: 1, flexGrow: 1, whitespace: "normal", paddingVertical: 8, paddingHorizontal: 8 }}>
                <View style={{ paddingBottom: 8, marginBottom: 8, borderBottom: "1px solid #cbd5e1" }}>
                  <Text style={{ fontSize: 10, display: "flex", flexDirection: "row", gap: 4 }}>
                    Upgraded to <Text style={{ fontFamily: "LatoBold" }}>{data?.plan_details?.name}</Text>, The next billing date has been changed to <Text style={{ fontFamily: "LatoBold" }}>{moment(data?.adjusted_data?.newPlanStartDate).format("MMM DD, YYYY")}</Text>.
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Previous Plan:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>
                      {data?.adjusted_data?.prevPlanDetails?.plan_details?.name} | {data?.adjusted_data?.prevPlanDetails?.amount}/{data?.adjusted_data?.prevPlanDetails?.subscription?.recurring_type}
                    </Text>
                  </View>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Upgraded Plan:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>
                      {data?.plan_details?.name} | {data?.amount}/year
                    </Text>
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Daily Rate:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>{formatCurrency(data?.adjusted_data?.currentPlanDailyPrice)}/day</Text>
                  </View>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Daily Rate:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>{formatCurrency(data?.adjusted_data?.newDailyPrice)}/day</Text>
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Remaining Balance:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>{formatCurrency(data?.adjusted_data?.remainingPrice)}</Text>
                  </View>
                  <View style={{ width: "50%", flexShrink: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                    <Text style={{ fontSize: 9 }}>Adjusted Days:</Text>
                    <Text style={{ fontSize: 9, fontFamily: "LatoBold" }}>{data?.adjusted_data?.daysDecreased.toFixed(0)} day(s)</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
        {["succeeded"].includes(data?.payment_status) && (
          <View style={{ paddingVertical: 20, borderTopWidth: 0.5, borderTopColor: "#cbd5e1", borderStyle: "solid", width: "100%" }}>
            <View style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 16, fontFamily: "LatoBold", textTransform: "Capitalize" }}>Payment Details</Text>
            </View>
            {data?.payment_method_details?.card_last4 && (
              <View style={{ paddingVertical: 2, flexDirection: "row", alignItems: "center", gap: "5px" }}>
                <Text style={{ fontSize: 11, fontFamily: "LatoBold" }}>Payment Method:</Text>
                <Text style={{ fontSize: 10, color: "#64748b" }}>{`${data?.payment_method_details?.brand} XXXX-XXXX-XXXX-${data?.payment_method_details?.card_last4}`}</Text>
              </View>
            )}
            <View style={{ paddingVertical: 2, flexDirection: "row", alignItems: "center", gap: "5px" }}>
              <Text style={{ fontSize: 11, fontFamily: "LatoBold" }}>Transaction ID:</Text>
              <Text style={{ fontSize: 10, color: "#64748b" }}>{data?.transaction_id || "TXN-2025-001"}</Text>
            </View>
            <View style={{ paddingVertical: 2, flexDirection: "row", alignItems: "center", gap: "5px" }}>
              <Text style={{ fontSize: 11, fontFamily: "LatoBold" }}>Total Paid:</Text>
              <Text style={{ fontSize: 10, color: "#64748b" }}>{formatCurrency(data?.amount)}</Text>
            </View>
          </View>
        )}
        <View style={{ paddingVertical: 20, borderTopWidth: 0.5, borderTopColor: "#cbd5e1", borderStyle: "solid", width: "100%" }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={{ fontSize: 16, fontFamily: "LatoBold", textTransform: "Capitalize" }}>{statusText[data?.payment_status]?.title}</Text>
          </View>

          <View style={{ position: "relative" }}>
            {["succeeded"].includes(data?.payment_status) && (
              <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: 2 }}>
                <Text style={{ color: "#64748b", fontSize: 10 }}>Your subscription to</Text>
                <Text style={{ color: "#000", fontSize: 10, fontFamily: "LatoBold" }}>{data?.plan_details?.name}</Text>
                <Text style={{ color: "#64748b", fontSize: 10 }}>is active</Text>
              </View>
            )}
            {["succeeded"].includes(data?.payment_status) && (
              <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: 2 }}>
                <Text style={{ color: "#64748b", fontSize: 10 }}>To manage your suscription, login to your account at</Text>
                <Link
                  src={data?.loginUrl}
                  style={{ fontSize: 10, color: "#1d4ed8", textDecoration: "underline" }}>
                  {data?.loginUrl}
                </Link>
              </View>
            )}
            <View style={{ paddingVertical: 1, flexDirection: "row", alignItems: "center", gap: 2 }}>
              <Text style={{ color: "#64748b", fontSize: 10 }}>For any questions, contact us at</Text>
              <Text style={{ color: "#3b82f6", fontSize: 10 }}>{data?.supportEmail}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
