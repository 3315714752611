import { classNames } from "src/helpers/classNames";
import Preloader from "../shared/LoadingAnimations/Preloader";
import TableBodyLoader from "./TableBodyLoader";
import TableBody from "./TableBody";

const Table = ({ children, tableHeader, dataExists = false, loaded = false, colSpan = 0, isPreloader = true }) => {
  return (
    <div className="relative flex flex-col">
      <div className="w-full">
        <div className="rounded-md shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg md:rounded-b-lg">
          <table className="w-full table-auto divide-y divide-gray-300">
            {tableHeader}
            <TableBody>
              {dataExists && children}
              <TableBodyLoader
                dataExists={dataExists}
                loaded={loaded}
                colSpan={colSpan}
              />
            </TableBody>
          </table>
        </div>
      </div>
      {isPreloader && (
        <div className={classNames("absolute -bottom-3.5 right-0 h-8 transition-all duration-500 ease-out sm:-right-6", dataExists && !loaded ? "opacity-100" : "opacity-0")}>
          <Preloader
            className="!h-[400px]"
            circleDimension="5"
            size="sm"
          />
        </div>
      )}
    </div>
  );
};

export default Table;
