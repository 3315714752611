import React from "react";
import Preloader from "../shared/LoadingAnimations/Preloader";
import NoData from "../nodata/NoData";

export default function TableBodyLoader({ dataExists = false, loaded = true, colSpan = "5" }) {
  let isLoadedWithNoData = () => loaded && !dataExists;

  return (
    <>
      {isLoadedWithNoData() && (
        <tr>
          <td colSpan={colSpan}>
            <NoData />
          </td>
        </tr>
      )}
      {!loaded && !dataExists && (
        <tr>
          <td colSpan={colSpan}>
            <Preloader
              className="min-h-[200px]"
              color="text-gray-400 fill-gray-700"
            />
          </td>
        </tr>
      )}
    </>
  );
}
