import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "src/components/form/Button";
import { formatCurrency } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import { cardtypes, getStatusStyles } from "src/helpers/planHelper";
import InvoicePDF from "./InvoicePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WorkspaceContext } from "src/context/workspace";
import { SiteContext } from "src/context/site";
import { convertBase64ToPng, convertToBase64 } from "src/helpers/image";
import { daysDifferent } from "src/helpers/dateHelper";

const InvoicesEntry = ({ data }) => {
  const { details: workspaceDetails } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const { className, label } = getStatusStyles(data?.payment_status, "transaction");

  const [logoBase64, setLogoBase64] = useState(null);
  const [logoLoading, setLogoLoading] = useState(false);

  useEffect(() => {
    const fetchLogo = async () => {
      const logo = workspaceDetails?.image_logo || site?.image_logo;
      if (logo) {
        try {
          setLogoLoading(true);
          const base64 = await convertToBase64(logo);
          if (!base64) {
            console.error("Failed to convert logo to base64");
            return;
          }
          const pngBase64 = await convertBase64ToPng(base64);
          setLogoBase64(pngBase64);
        } catch (error) {
          console.error("Error converting logo:", error);
        } finally {
          setLogoLoading(false);
        }
      }
    };
    fetchLogo();
  }, [workspaceDetails?.image_logo, site?.image_logo]);

  const isAdjust = data?.adjusted_transaction_details?.transaction_id;
  const prevTransaction = data?.adjusted_transaction_details?.transaction_id;
  const prevPlan = prevTransaction?.plan_details;
  const prevPlanPricePerDay = prevPlan?.pricing?.rates?.find((item) => item?.interval === prevTransaction?.subscription?.recurring_type)?.amount / (prevTransaction?.subscription?.recurring_type === "year" ? 365 : 30);

  const adjustCalculator = useMemo(() => {
    const currentPlanDailyPrice = prevTransaction?.amount / daysDifferent(new Date(prevTransaction?.start_date), new Date(prevTransaction?.end_date));
    const yearlyRate = data?.plan_details?.pricing?.rates?.find((item) => item?.interval === "year");
    const changePlanDailyPrice = (yearlyRate?.amount - (yearlyRate?.discount_amount || 0)) / 365;

    const currentDate = new Date();
    const startDate = new Date(prevTransaction?.start_date);
    const prevPlanEndDate = new Date(prevTransaction?.end_date);

    const daysUsed = daysDifferent(startDate, currentDate);
    const priceUsed = currentPlanDailyPrice * daysUsed;
    const remainingPrice = prevTransaction?.amount - priceUsed;

    const daysTobeAdjusted = Math.floor(remainingPrice / changePlanDailyPrice);

    const newPlanBillingStartDate = new Date(prevTransaction?.start_date);
    newPlanBillingStartDate.setDate(newPlanBillingStartDate.getDate() + daysTobeAdjusted);

    return {
      newDailyPrice: changePlanDailyPrice.toFixed(2),
      prevPlanEndDate: prevPlanEndDate.toLocaleDateString(),
      newPlanStartDate: newPlanBillingStartDate.toLocaleDateString(),
      currentPlanDailyPrice,
      daysDecreased: daysDifferent(prevTransaction?.start_date, prevTransaction?.end_date) - daysTobeAdjusted,
      remainingPrice,
    };
  }, [prevTransaction, data?.plan_details]);

  const renderPaymentMethod = () => {
    if (data?.payment_method_details?.card_last4) {
      return (
        <div className="flex items-center gap-3">
          <div
            className="w-10"
            dangerouslySetInnerHTML={{ __html: data?.payment_method_details?.brand ? cardtypes[data?.payment_method_details?.brand] : "" }}
          />
          <div className="font-medium text-gray-900">{`XXXX-XXXX-XXXX-${data?.payment_method_details?.card_last4}`}</div>
        </div>
      );
    }
    return <div className="ml-0.5 font-semibold">{isAdjust ? "Adjusted" : "N/A"}</div>;
  };

  const renderDownloadButton = () => {
    if (!["canceled"].includes(data?.payment_status)) {
      return (
        <div className="flex justify-end font-medium text-gray-900">
          <PDFDownloadLink
            className={logoLoading ? "pointer-events-none" : ""}
            document={
              <InvoicePDF
                data={{
                  ...data,
                  workspaceName: workspaceDetails?.name,
                  logo: logoBase64,
                  supportEmail: site?.site_email,
                  loginUrl: window.location.origin,
                  adjusted_data: { ...adjustCalculator, prevPlanPricePerDay, isAdjust, prevPlanDetails: prevTransaction },
                }}
              />
            }
            fileName="payment-details.pdf">
            {({ loading }) => (
              <Button
                buttonLabel={""}
                buttonIconPosition={"left"}
                isDisable={loading}
                buttonClasses={"!h-10 !bg-highlightColor"}
                buttonIcon={ArrowDownTrayIcon}
              />
            )}
          </PDFDownloadLink>
        </div>
      );
    }
    return null;
  };

  return (
    <tr key={data?._id}>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5 sm:pl-6">
        <div className="font-medium text-gray-900">{moment(data?.created_at).format("MMM DD, YYYY")}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">{renderPaymentMethod()}</td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="font-medium text-gray-900">{formatCurrency(isAdjust ? 0 : data?.amount)}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className={classNames("inline-flex h-6 gap-2 rounded-md border px-2 text-sm", className)}>{label}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">{renderDownloadButton()}</td>
    </tr>
  );
};

export default InvoicesEntry;
