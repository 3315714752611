import { ArrowPathIcon, CursorArrowRaysIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React from "react";
import QuickMenu from "src/components/Table/QuickMenu";
import { cardtypes } from "src/helpers/planHelper";

const PaymentMethodEntry = ({ data }) => {
  const menuItems = [
    {
      name: "Set as Default",
      onClick: () => {
        data?.defaultFunction(data);
      },
      hideWhen: data?.default,
      buttonIconClass: data?.isDefaultLoading ? "!animate-spin" : "",
      icon: data?.isDefaultLoading ? ArrowPathIcon : CursorArrowRaysIcon,
      color: "!bg-white !text-blue-500",
    },
    {
      name: "hr",
      hideWhen: data?.default,
      type: "hr",
    },
    {
      name: "Edit",
      onClick: () => {
        data?.editModalFunction();
        data?.selectCardFunction(data);
      },
      icon: PencilIcon,
      color: "!bg-white !text-black",
    },
    {
      name: "hr",
      hideWhen: data?.default,
      type: "hr",
    },
    {
      name: "Delete",
      hideWhen: data?.default,
      onClick: () => {
        data?.deleteFunction();
        data?.selectCardFunction(data);
      },
      icon: TrashIcon,
      color: "!bg-white !text-red-500",
    },
  ];

  return (
    <tr key={data?.card_id}>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5 sm:pl-6">
        <div className="font-medium text-gray-900">{data?.paymentMethod || "Card"}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex items-center  gap-3">
          <div
            className="w-10"
            dangerouslySetInnerHTML={{ __html: data?.brand ? cardtypes[data?.brand] : "" }}></div>
          <div className="font-medium text-gray-900">{`XXXX-XXXX-XXXX-${data?.last4}`}</div> {data?.default && <div className="flex h-6 w-16 items-center justify-center rounded-md border bg-blue-100 font-medium text-blue-500">Default</div>}
        </div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex  font-medium">
          Expires <span className="text-gray-800">{moment(`${data?.exp_year}-${data?.exp_month}-01`).format("MMM, YYYY")}</span>
        </div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="flex justify-end">
          <QuickMenu items={menuItems} />
        </div>
      </td>
    </tr>
  );
};

export default PaymentMethodEntry;
