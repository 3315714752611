import Modal from "src/components/shared/Modal";

const CardDeleteModal = ({ isOpen = false, disable = false, onSuccess = () => {}, onCancel = () => {} }) => {
  return (
    <Modal
      title={"Card"}
      secondaryTitle="Delete"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: "Yes",
        onSuccessButtonVisible: true,
        onSuccessLoaderStart: disable,
        onSuccessLoaderVisible: true,
        onCancelButtonVisible: true,
        onCancelButtonText: "No",
      }}>
      Are you sure you want to delete the card?
    </Modal>
  );
};

export default CardDeleteModal;
